import React from "react";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import ProTip from "./ProTip";
import Investments from "./Investments";
import ErrorBoundary from "./ErrorBoundary";
import AuthenticatorWrapper from "./AuthenticatorWrapper";

import "./App.css";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

function AuthTokenStore(props: any) {
  const token = props.oldprops.authData.signInUserSession.idToken.jwtToken;
  localStorage.setItem("cognito", token);
  return <></>;
}
export default function App() {
  return (
    <ErrorBoundary>
      <AuthenticatorWrapper>
        <AuthTokenStore></AuthTokenStore>
        <Container maxWidth="sm">
          <Box my={4}>
            <Typography variant="h4" component="h1" gutterBottom>
              Portfolio Aggregator v1
            </Typography>
            <Investments></Investments>
            <ProTip />
            <Copyright />
          </Box>
        </Container>
      </AuthenticatorWrapper>
    </ErrorBoundary>
  );
}
