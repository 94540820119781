import { MenuItem, Select, SelectProps } from "@material-ui/core";
import React from "react";

export const Currency = (props: SelectProps) => {
  const currencies = [
    {
      code: "USD",
      symbol: "$",
      name: "US Dollar",
    },
    {
      code: "GBP",
      symbol: "£",
      name: "Pound Sterling",
    },
    {
      code: "GBX",
      symbol: "p",
      name: "Penny Sterling",
    },
  ];
  return (
    <Select {...props}>
      {currencies.map((currency, index) => {
        return (
          <MenuItem key={index} value={currency.code}>
            {currency.symbol} {currency.name}
          </MenuItem>
        );
      })}
    </Select>
  );
};
