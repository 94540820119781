import {
  Button,
  Card,
  CardContent,
  Chip,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  Snackbar,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";
import { AddCircleOutlineOutlined } from "@material-ui/icons";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import React from "react";
import AddAccountDialog from "./AddAccount";
import AddInvestmentDialog from "./AddInvestmentDialog";
import DeleteAccounts from "./DeleteAccounts";
import MuiAlert from "@material-ui/lab/Alert";
import {
  getPortfolioV2,
  getQuery,
  getQueryForAccounts,
  getQueryIO,
  InvestmentQuery,
  updateCash,
  updateInvestment,
} from "./helper";
import { Portfolio, StandardInvestment } from "./Model";
import Alert from "@material-ui/lab/Alert";
import { SimpleInvestment, InvestmentAccountQuery } from "./Investments";

export type EditInvestmentProps = {
  //pick the first account for broker
  accountId: string;
  type: "stock" | "etf";
  brokerId: string;
  totalValue: number;
  investments: SimpleInvestment[];
  traits: string[];
};

export type EditInvestmentPropsV2 = {
  investments: InvestmentQuery[];
  cash: InvestmentAccountQuery[];
  successCallback: Function;
};

type SimpleInvestmentEditable = {
  name: string;
  quantity: string;
};
type EditInvestmentState = {
  initial: InvestmentQuery[];
  cash: InvestmentAccountQuery[];
  editValues: SimpleInvestmentEditable[];
  cashValues: SimpleInvestmentEditable[];
  saving: boolean;
  errorMessage: string;
  isSnackbarOpen: boolean;
  brokerId: string;
  successCallback: Function;
};

export default class EditableInvestment extends React.Component<
  EditInvestmentPropsV2,
  EditInvestmentState
> {
  constructor(props: EditInvestmentPropsV2) {
    super(props);

    this.state = {
      isSnackbarOpen: false,
      errorMessage: "",
      successCallback: this.props.successCallback,
      brokerId: props.cash[0].brokerId,
      initial: props.investments,
      cash: props.cash,
      saving: false,
      editValues: [],
      cashValues: [],
    };
  }

  componentDidMount() {
    this.loadSaved();
  }

  loadSaved() {
    Promise.all(this.state.initial.map((x) => getQueryIO(x)))
      .then((x) => {
        this.setState({
          editValues: x.map((xx) => {
            return {
              name:
                xx.output.humanReadableName +
                " " +
                xx.input.investmentAccountId,
              quantity: xx.output.numberOfStocks.toString(),
            };
          }),
        });
      })
      .then(() =>
        Promise.all(this.state.cash.map((x) => getQueryForAccounts(x))).then(
          (x) => {
            this.setState({
              saving: false,
              cashValues: x.map((xx) => {
                return {
                  name: xx.id,
                  quantity: xx.originalCashAmount.toString(),
                };
              }),
            });
          }
        )
      );
  }
  saveStockEdit() {
    this.setState({ saving: true });
    this.state.editValues.forEach((i, index) => {
      updateInvestment(this.state.initial[index], Number(i.quantity));
    });
    this.state.cashValues.forEach((i, index) => {
      const x = updateCash(this.state.cash[index], Number(i.quantity));
      console.log("promise" + x.toString());
      x.then((x) => {
        console.log("PROMOISE success");
        this.loadSaved();
        this.state.successCallback();
      }).catch((e) => {
        console.log("FAILED PROMOISE");
        this.setState({
          saving: false,
          isSnackbarOpen: true,
          errorMessage: e.toString(),
        });
      });
    });
  }

  handleSnackbarClose(event?: React.SyntheticEvent, reason?: string) {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ isSnackbarOpen: false, errorMessage: "" });
  }
  render() {
    if (this.state === null) {
      return <></>;
    }
    return (
      <>
        <Card>
          <CardContent>
            <Typography variant="h5" component="h2">
              {this.state.brokerId}
            </Typography>
            <Typography variant="h6" component="h2"></Typography>
            {this.state.editValues.map((i, index) => {
              return (
                <React.Fragment key={index}>
                  {/* <FormLabel>{i}</FormLabel>   <TextField label="Outlined" variant="outlined" /> */}
                  <br />
                  <FormControl>
                    <InputLabel htmlFor="my-input"></InputLabel>
                    <TextField
                      disabled={this.state.saving}
                      type="number"
                      onChange={(e) => {
                        const newVal = e.target.value;
                        const currentEditValue = this.state.editValues;
                        currentEditValue[index].quantity = newVal;
                        this.setState({ editValues: currentEditValue });
                      }}
                      //id="my-input"
                      aria-describedby="my-helper-text"
                      value={i.quantity}
                    />
                    <FormHelperText id="my-helper-text">
                      {i.name}
                    </FormHelperText>
                  </FormControl>
                </React.Fragment>
              );
            })}
            <br /> <br />{" "}
            <Typography variant="h6" component="p">
              Cash
            </Typography>
            {this.state.cashValues.map((i, index) => {
              return (
                <React.Fragment key={index}>
                  {/* <FormLabel>{i}</FormLabel>   <TextField label="Outlined" variant="outlined" /> */}
                  <br />
                  <FormControl>
                    {/* <InputLabel htmlFor="my-input"></InputLabel> */}
                    <TextField
                      disabled={this.state.saving}
                      type="number"
                      onChange={(e) => {
                        const newVal = e.target.value;
                        const currentEditValue = this.state.cashValues;
                        currentEditValue[index].quantity = newVal;
                        this.setState({ cashValues: currentEditValue });
                      }}
                      //id="my-input"
                      aria-describedby="my-helper-text"
                      value={i.quantity}
                    />
                    <FormHelperText id={"my-helper-text-cash" + index}>
                      {i.name}
                    </FormHelperText>
                  </FormControl>
                </React.Fragment>
              );
            })}
            {/* <Typography variant="body2" component="p"></Typography> */}
            <br />
            <br />
            <Button
              onClick={this.saveStockEdit.bind(this)}
              size="small"
              variant="contained"
              color="primary"
              component="span"
            >
              Save
            </Button>
            {this.state.saving && (
              <>
                <br />
                <br />
                <CircularProgress size={30} />
              </>
            )}
            <br />
            <br />
            <AddInvestmentDialog
              //what is cash? rename to investment account where applicable
              accounts={this.state.cash}
            ></AddInvestmentDialog>{" "}
            <DeleteAccounts
              successCallback={this.state.successCallback}
              brokerId={this.state.brokerId}
            ></DeleteAccounts>
          </CardContent>
        </Card>
        <br />

        <Snackbar
          open={this.state.isSnackbarOpen}
          autoHideDuration={6000}
          onClose={this.handleSnackbarClose.bind(this)}
        >
          <Alert onClose={this.handleSnackbarClose.bind(this)} severity="error">
            {this.state.errorMessage}
          </Alert>
        </Snackbar>
      </>
    );
  }
}
