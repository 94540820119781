import React from "react";
import { Button, TextField } from "@material-ui/core";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  addAccount,
  addInvestment,
  getPortfolioV2,
  getQueryForAccounts,
} from "./helper";
import { InvestmentAccountQuery, InvestmentProps } from "./Investments";
import { Portfolio } from "./Model";
import { ThreeSixty } from "@material-ui/icons";
import { Currency } from "./Utiils";

type AddAccountDialogProps = {
  successCallback: Function;
  brokerIds: string[];
};

type AddAccountDialogInputs = {
  brokerIndex: string;
  balance: string;
  accountIndex: string;
  currencyCode: string;
  error: string;
  newBrokerName?: string;
};

type AddAccountDialogState = AddAccountDialogInputs & {
  open: boolean;
  brokers: string[];
  saving: boolean;
  successCallback: Function;
};

export default class AddAccountDialog extends React.Component<
  AddAccountDialogProps,
  AddAccountDialogState
> {
  constructor(props: AddAccountDialogProps) {
    super(props);
    this.state = {
      open: false,
      successCallback: this.props.successCallback,
      brokerIndex: "0",

      brokers: ["[Create new broker]", ...props.brokerIds],
      error: "",
      accountIndex: "0",
      currencyCode: "USD",
      balance: "0",
      newBrokerName: "",
      saving: false,
    };
  }

  handleClose() {
    this.setState({ error: "", open: false });
  }
  handleClickOpen() {
    this.setState({ open: true });
  }

  render() {
    return (
      <div>
        <Button
          variant="outlined"
          color="primary"
          onClick={this.handleClickOpen.bind(this)}
        >
          Add new investment account
        </Button>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose.bind(this)}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            Add new investment account
          </DialogTitle>
          <DialogContent>
            <DialogContentText>{this.state.error}</DialogContentText>
            <fieldset
              style={{
                border: 0,
              }}
              disabled={this.state.saving}
            >
              Broker:{" "}
              <select
                onChange={(e) => {
                  this.setState({ brokerIndex: e.target.value });
                }}
                name="broker"
                id="broker"
                value={this.state.brokerIndex}
              >
                {this.state.brokers.map((i, index) => (
                  <option key={index} value={index}>
                    {i}
                  </option>
                ))}
                ;
              </select>{" "}
              <br />
              {this.state.brokerIndex === "0" && (
                <>
                  <label>Broker name</label>{" "}
                  <input
                    onChange={(e) => {
                      this.setState({ newBrokerName: e.target.value });
                    }}
                    value={this.state.newBrokerName}
                    type="text"
                  />
                  <br />
                </>
              )}
              <label>Cash Balance</label>
              <br />
              <label>Currency</label>
              <Currency
                onChange={(e: any) => {
                  this.setState({ currencyCode: e.target.value });
                }}
                value={this.state.currencyCode}
              />
              <TextField
                type="number"
                onChange={(e) => {
                  this.setState({ balance: e.target.value });
                }}
                value={this.state.balance}
              />
              <br />
            </fieldset>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose.bind(this)} color="primary">
              Cancel
            </Button>
            <Button
              onClick={() => {
                if (this.state.brokerIndex === "0") {
                  if (
                    this.state.newBrokerName == null ||
                    this.state.newBrokerName.replaceAll(" ", "") == ""
                  ) {
                    this.setState({ error: "Brokers name must be valid" });
                    return;
                  } else if (this.state.newBrokerName in this.state.brokers) {
                    this.setState({ error: "Broker in use" });
                    return;
                  }
                }
                if (
                  this.state.currencyCode.replaceAll(" ", "") === "" ||
                  this.state.currencyCode.length != 3
                ) {
                  this.setState({ error: "Currency not valid" });
                  return;
                }

                this.setState({ error: "" });
                try {
                  let brokerId =
                    this.state.brokerIndex == "0"
                      ? this.state.newBrokerName
                      : this.state.brokers[Number(this.state.brokerIndex)];

                  if (!brokerId) {
                    this.setState({ error: "Broker in use" });
                    return;
                  }

                  this.setState({ error: "Saving..." });

                  addAccount({
                    brokerId,
                    currencyCode: this.state.currencyCode,
                    balance: Number(this.state.balance),
                  })
                    .then(() => {
                      this.state.successCallback();
                      this.handleClose();
                    })
                    .catch((e) => {
                      this.setState({ error: e.toString() });
                    });
                } catch (e) {
                  this.setState({ error: e.toString() });
                }
              }}
              color="primary"
            >
              Add
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
/*
Broker
trading212

Broker name

Cash balance

3 dig currency code of cas

  */
