import { InvestmentAccountQuery } from "./Investments";
import {
  BrokerageAccount,
  InvestmentAccount,
  Portfolio,
  StandardInvestment,
} from "./Model";

const URL_PREFX = "https://hjy71f301b.execute-api.eu-west-2.amazonaws.com/Prod";

type PortfolioRaw = {
  id: string;
  currency: string;
  brokerageAccounts: BrokerageAccount[];
  earliestFetchDate: string;
  cacheDurationInMilliseconds: number;
};

export type InvestmentMultiQuery = {
  brokerId: string;
  investmentAccountId: string;
  type: "stock" | "etf";
  investmentId: string | undefined;
  portfolioVersion?: number;
};

export type InvestmentQuery = {
  brokerId: string;
  investmentAccountId: string;
  type: "stock" | "etf";
  investmentId: string;
  portfolioVersion?: number;
};

type NewInvestmentParameters = {
  exchange: string;
  ticker: string;
  currencyCode: string;
  investmentAccountQuery: InvestmentAccountQuery;
  type: "stock" | "etf";
  quantity: number;
};

type NewAccountParameters = {
  brokerId: string;
  currencyCode: string;
  balance: number;
};

async function callServer(path: String, method: "GET" | "POST", bodyRaw?: any) {
  const body = bodyRaw == null ? null : JSON.stringify(bodyRaw);
  try {
    return await fetch(`${URL_PREFX}${path}`, {
      method,
      // mode: "cors", // no-cors, *cors, same-origin
      // cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("cognito"),
      },
      redirect: "follow", // manual, *follow, error
      body,
    });
  } catch (e) {
    return Promise.reject(typeof e == "string" ? e : e.toString());
  }
}

export function getPortfolioV2() {
  return callServer("/v1/view", "GET")
    .then((x) => {
      if (x.status != 200) {
        return x.text().then((reponseText) => {
          return Promise.reject(
            `Failed to fetch portfolio from server: ${x.status} ${reponseText}`
          );
        });
      }
      return x.json();
    })
    .then(
      (rawPortfolio: PortfolioRaw): Portfolio => {
        const rwv = Object.assign(rawPortfolio, {
          earliestFetchDate: rawPortfolio.earliestFetchDate
            ? Date.parse(rawPortfolio.earliestFetchDate)
            : null,
        });

        console.log(rwv);
        return rwv;
      }
    );
}

export function getQueryForAccounts(
  query: InvestmentAccountQuery
): Promise<InvestmentAccount> {
  return getPortfolioV2().then((portfolio) => {
    const queryResult = portfolio.brokerageAccounts
      .filter((broker) => broker.brokerId == query.brokerId)
      .flatMap((x) => x.investmentAccounts)
      .filter((x) => x.id == query.investmentAccountId);

    if (queryResult.length != 1) {
      throw new Error("Unable to find query " + JSON.stringify(query));
    }

    return queryResult[0];
  });
}

export function getQuery(query: InvestmentQuery): Promise<StandardInvestment> {
  return getPortfolioV2().then((portfolio) => {
    const queryResult = portfolio.brokerageAccounts
      .filter((broker) => broker.brokerId == query.brokerId)
      .flatMap((x) => x.investmentAccounts)
      .filter((x) => x.id == query.investmentAccountId)
      .flatMap((x) => (query.type === "stock" ? x.stocks : x.etfs))
      .filter((x) => x.id == query.investmentId);

    if (queryResult.length != 1) {
      throw new Error("Unable to find query " + JSON.stringify(query));
    }

    return queryResult[0];
  });
}
export function getQueryIO(
  query: InvestmentQuery
): Promise<{ input: InvestmentQuery; output: StandardInvestment }> {
  return getQuery(query).then((result) => {
    return { input: query, output: result };
  });
}

export function updateCash(query: InvestmentAccountQuery, newAmount: number) {
  return callServer("/v1/update-cash", "POST", { query, newAmount }).then(
    (x) => {
      if (x.status != 201) {
        return x.text().then((b) => {
          return Promise.reject("Failed to update cash: " + b + " " + x.status);
        });
      }
    }
  );
}

export function updateInvestment(query: InvestmentQuery, newAmount: number) {
  return callServer("/v1/update-investment", "POST", { query, newAmount }).then(
    (x) => {
      if (x.status != 201) {
        return x.text().then((b) => {
          return Promise.reject(
            "Failed to update investment: " + b + " " + x.status
          );
        });
      }
    }
  );
}

export function deleteBroker(brokerId: string) {
  return callServer("/v1/delete-broker", "POST", { brokerId }).then((x) => {
    if (x.status != 201) {
      return x.text().then((b) => {
        return Promise.reject("Failed to delete broker: " + b + " " + x.status);
      });
    }
  });
}

export function addAccount(accountParams: NewAccountParameters) {
  return callServer("/v1/add-investment-account", "POST", {
    ...accountParams,
  }).then((x) => {
    if (x.status != 201) {
      return x.text().then((b) => {
        return Promise.reject(
          "Failed to add investment account: " + b + " " + x.status
        );
      });
    }
  });
}

export function addInvestment(newInvestment: NewInvestmentParameters) {
  return callServer("/v1/add-investment", "POST", {
    ...newInvestment,
  }).then((x) => {
    if (x.status != 201) {
      return x.text().then((b) => {
        if (
          //Hack!
          b.includes(
            "Cannot deserialize value of type `java.util.Currency` from String"
          )
        ) {
          return Promise.reject(
            "Failed to add investment account: Currency is not valid"
          );
        }
        return Promise.reject(
          "Failed to add investment account: " + b + " " + x.status
        );
      });
    }
  });
}
