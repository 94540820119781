import {
  Card,
  CardContent,
  Chip,
  Paper,
  Snackbar,
  Switch,
  Typography,
} from "@material-ui/core";
import {
  AddCircleOutlineOutlined,
  TransferWithinAStationSharp,
} from "@material-ui/icons";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import React from "react";
import AddAccountDialog from "./AddAccount";
import AddInvestmentDialog from "./AddInvestmentDialog";
import MuiAlert from "@material-ui/lab/Alert";
import moment from "moment";
import {
  getPortfolioV2,
  getQuery,
  getQueryForAccounts,
  updateInvestment,
} from "./helper";
import { BrokerageAccount, Portfolio } from "./Model";
import Alert from "@material-ui/lab/Alert";
import EditableInvestment from "./EditInvestment";
import humanize from "humanize-duration";
import { Skeleton } from "@material-ui/lab";
export type InvestmentQuery = {
  brokerId: string;
  investmentAccountId: string;
  type: "stock" | "etf";
  investmentId: string;
  portfolioVersion?: number;
};

export type InvestmentsAndCash = {
  cash: InvestmentAccountQuery[];
  investments: InvestmentQuery[];
};
export type InvestmentAccountQuery = {
  brokerId: string;
  investmentAccountId: string;
  portfolioVersion?: number;
};

export type SimpleInvestment = {
  name: string;
  quantity: number;
};

export type InvestmentProps = {
  brokerageAccount: BrokerageAccount;
};

type InvestmentsProps = {};

interface ViewInvestments extends InvestmentProps {}
type InvestmentsState = {
  editingEnabled: boolean;
  investments: ViewInvestments[];
  brokerIds?: string[];
  portfolio?: Portfolio;
  investmentAccountQueries: {
    investments: InvestmentQuery[];
    cash: InvestmentAccountQuery[];
  }[];
  hasError: boolean;
  errorMessage?: string;
  isSnackbarOpen: boolean;
};

export default class Investments extends React.Component<
  InvestmentsProps,
  InvestmentsState
> {
  constructor(props: InvestmentsProps) {
    super(props);

    // TODO differentiiate editinvestments from query.
    // cash in EditInvestmentPropsV2 seems redudant. ths can be parsed in editinvestment
    this.state = {
      editingEnabled: false,
      investments: [],
      investmentAccountQueries: [],
      hasError: false,
      isSnackbarOpen: false,
    };
  }

  componentDidMount() {
    this.refreshAll();
  }

  refreshAll() {
    getPortfolioV2()
      .then((portfolio) => {
        const { editInvestments } = this.getLatestPortfolio(portfolio);

        const brokerIds = editInvestments
          .flatMap((x) => x.cash)
          .map((x) => x.brokerId);
        this.setState({
          portfolio,
          brokerIds,
          investmentAccountQueries: editInvestments,
        });
      })
      .catch((x) => {
        console.log(x);
        this.setState({
          hasError: true,
          isSnackbarOpen: true,
          errorMessage: x,
        });
      });
  }

  handleSnackbarClose(event?: React.SyntheticEvent, reason?: string) {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ isSnackbarOpen: false });
  }

  getLatestPortfolio(portfolio: Portfolio) {
    const editInvestments = portfolio.brokerageAccounts.map((item) => {
      const brokerId = item.brokerId;
      let investments = item.investmentAccounts.flatMap((investmentAccount) => {
        const stocksQueries: InvestmentQuery[] = investmentAccount.stocks.flatMap(
          (stock) => {
            return {
              brokerId,
              investmentAccountId: investmentAccount.id,
              type: "stock",
              investmentId: stock.id,
            };
          }
        );

        const etfQueries: InvestmentQuery[] = investmentAccount.etfs.flatMap(
          (etf) => {
            return {
              brokerId,
              investmentAccountId: investmentAccount.id,
              type: "etf",
              investmentId: etf.id,
            };
          }
        );

        return [...stocksQueries, ...etfQueries];
      });

      const cash = item.investmentAccounts.map((ia) => {
        return { brokerId: brokerId, investmentAccountId: ia.id };
      });

      return {
        cash,
        investments,
      };
    });
    return { editInvestments };
  }

  forceUpdatePortfolio() {}
  render() {
    let labelMessage;
    if (
      this.state.portfolio &&
      this.state.portfolio.earliestFetchDate == null
    ) {
      labelMessage = "Stock prices are up to date";
    } else if (
      this.state.portfolio &&
      this.state.portfolio.earliestFetchDate != null
    ) {
      const momentFetched = moment
        .duration(moment(this.state.portfolio.earliestFetchDate).diff(moment()))
        .humanize();

      const expireDateTime =
        this.state.portfolio.earliestFetchDate +
        this.state.portfolio.cacheDurationInMilliseconds;

      const now = new Date();

      const momentExpiry = moment
        .duration(moment(expireDateTime).diff(moment(now)))
        .humanize();

      // if (expireDateTime > now.getMilliseconds()) {
      //   labelMessage = `Stock prices and exchange rates were fetched ${momentFetched} ago and expired ${momentExpiry}`;
      // } else {
      //   labelMessage = `Stock prices and exchange rates were fetched ${momentFetched} ago and will expire in ${momentExpiry}`;
      // }
      labelMessage = `Stock prices and exchange rates were fetched ${momentFetched} ago.`;
    }

    if (!this.state.portfolio) {
      return (
        <div>
          <Skeleton>
            <Chip label="Stock prices and exchange rates were fetched ${} ago"></Chip>
          </Skeleton>
          <br />
          <br />
          <Typography variant="body1">
            <Skeleton width={200}></Skeleton>
            <Skeleton>
              <Switch
                checked={this.state.editingEnabled}
                name="editCheckbox"
                color="primary"
              />
            </Skeleton>
            <br />
            <br />
          </Typography>
          <Summary portfolio={this.state.portfolio}></Summary>
        </div>
      );
    }
    return (
      <div>
        {labelMessage && (
          <>
            {" "}
            <Typography>{labelMessage}</Typography>
            <br />
            <br />
          </>
        )}

        <Typography variant="body1">
          Enable editing
          <Switch
            checked={this.state.editingEnabled}
            onChange={() => {
              this.setState({
                editingEnabled: !this.state.editingEnabled,
              });
            }}
            name="editCheckbox"
            color="primary"
          />
          <br />
          <br />
          {/* <Button size="small" variant="contained" color="primary" component="span">Add account</Button> <br/><br/> */}
        </Typography>
        {this.state.editingEnabled && this.state.brokerIds && (
          <>
            <AddAccountDialog
              brokerIds={this.state.brokerIds}
              successCallback={this.refreshAll.bind(this)}
            ></AddAccountDialog>
            <br />
            {/* <Button
              onClick={this.forceUpdatePortfolio.bind(this)}
              size="small"
              variant="contained"
              color="primary"
            >
              Force update
            </Button> */}
            <br />

            <br />
          </>
        )}
        <Summary portfolio={this.state.portfolio}></Summary>
        {!this.state.editingEnabled &&
          this.state.portfolio &&
          this.state.portfolio.brokerageAccounts.map((i, index) => {
            return <Investment key={index} brokerageAccount={i}></Investment>;
          })}
        {this.state.editingEnabled &&
          this.state.investmentAccountQueries.map((i, index) => {
            return (
              <EditableInvestment
                successCallback={this.refreshAll.bind(this)}
                key={index}
                cash={i.cash}
                investments={i.investments}
              ></EditableInvestment>
            );
          })}
        {/* Should be separate element */}
        <Snackbar
          open={this.state.isSnackbarOpen}
          autoHideDuration={20000}
          onClose={this.handleSnackbarClose.bind(this)}
        >
          <Alert onClose={this.handleSnackbarClose.bind(this)} severity="error">
            {this.state.errorMessage}
          </Alert>
        </Snackbar>
      </div>
    );
  }
}

type SummaryProps = {
  portfolio?: Portfolio;
};
function Summary(props: SummaryProps) {
  const totalCash =
    props.portfolio != null
      ? props.portfolio.brokerageAccounts
          .flatMap((x) => x.investmentAccounts)
          .map((x) => x.cash.value)
          .reduce((a, b) => a + b, 0)
      : 0;

  const totalEtf =
    props.portfolio != null
      ? props.portfolio.brokerageAccounts
          .flatMap((x) => x.investmentAccounts)
          .flatMap((x) => x.etfs)
          .map((x) => x.localValue.value)
          .reduce((a, b) => a + b, 0)
      : 0;

  const totalStocks =
    props.portfolio != null
      ? props.portfolio.brokerageAccounts
          .flatMap((x) => x.investmentAccounts)
          .flatMap((x) => x.stocks)
          .map((x) => x.localValue.value)
          .reduce((a, b) => a + b, 0)
      : 0;

  const totalEquity = totalStocks + totalEtf;
  const totalValue = totalEquity + totalCash;
  return (
    <>
      <Card>
        <CardContent>
          <Typography variant="h4" component="h2">
            Summary
          </Typography>
          <Typography variant="h6" component="h2">
            {!props.portfolio ? (
              <>
                <Skeleton></Skeleton>
                <Skeleton></Skeleton>
                <Skeleton></Skeleton>
              </>
            ) : (
              <>
                Total value: £{totalValue.toLocaleString()} <br />
                Total cash: £{totalCash.toLocaleString()} <br />
                Total equity: £{totalEquity.toLocaleString()} <br />
              </>
            )}
          </Typography>
          <Typography variant="body2" component="p">
            {/* {"1x S&P 500 Index Fund (VUSA)"}  */}
          </Typography>
        </CardContent>
      </Card>
      <br />
    </>
  );
}

function Investment(props: InvestmentProps) {
  const traits = props.brokerageAccount.brokerId == "vanguard" ? ["ISA"] : [];
  const totalCash = props.brokerageAccount.investmentAccounts
    .map((x) => x.cash.value)
    .reduce((a, b) => a + b, 0);

  const totalEtfs = props.brokerageAccount.investmentAccounts
    .flatMap((x) => x.etfs)
    .map((x) => x.localValue.value)
    .reduce((a, b) => a + b, 0);

  const totalStocks = props.brokerageAccount.investmentAccounts
    .flatMap((x) => x.stocks)
    .map((x) => x.localValue.value)
    .reduce((a, b) => a + b, 0);

  const totalValue = totalStocks + totalEtfs + totalCash;
  const ia = props.brokerageAccount.investmentAccounts.flatMap((x) => [
    ...x.etfs,
    ...x.stocks,
  ]);
  return (
    <>
      <Card>
        <CardContent>
          {traits.map((trait) => {
            return (
              <React.Fragment key={trait}>
                <Chip key={trait} size="small" label={trait} />
                &nbsp;
              </React.Fragment>
            );
          })}
          <Typography variant="h5" component="h2">
            {props.brokerageAccount.brokerId}
          </Typography>
          <Typography variant="h6" component="h2">
            £{totalValue.toLocaleString()}
          </Typography>
          {/* TODO list investments */}
          {ia.length > 0 && (
            <Typography variant="body2" component="p">
              {ia.map((i, index) => {
                return (
                  <React.Fragment key={index}>
                    {i.humanReadableName}
                    <br />
                  </React.Fragment>
                );
              })}
              {/* {"1x S&P 500 Index Fund (VUSA)"}  */}
            </Typography>
          )}
        </CardContent>
      </Card>
      <br />
    </>
  );
}
