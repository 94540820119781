import React from "react";
import { Amplify } from "aws-amplify";
import { withAuthenticator } from "aws-amplify-react";
Amplify.configure({
  Auth: Object.assign(
    {},
    {
      identityPoolId: "eu-west-2:e76f53c7-c4ec-4711-b549-c0540e0679e3",
      region: "eu-west-2",
      userPoolId: "eu-west-2_jYqGTM7T0",
      userPoolWebClientId: "41m5jhq4jmbu4aa2cr8nf9djlp",
    }
  ),
});
class AuthenticatonWrapper extends React.Component {
  render() {
    let updatedChildren = React.Children.map(this.props.children, (child) => {
      return React.cloneElement(child, { oldprops: { ...this.props } });
    });

    return <div>{updatedChildren}</div>;
  }
}

export default withAuthenticator(AuthenticatonWrapper, true);
