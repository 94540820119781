import React from "react";
import {
  Button,
  MenuItem,
  Select,
  SelectProps,
  Snackbar,
  TextField,
} from "@material-ui/core";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { addInvestment, getPortfolioV2, getQueryForAccounts } from "./helper";
import { InvestmentAccountQuery, InvestmentProps } from "./Investments";
import { Portfolio } from "./Model";
import { Alert } from "@material-ui/lab";
import { Currency } from "./Utiils";

type AddInvestmentDialogProps = {
  accounts: InvestmentAccountQuery[];
};

type AddInvestmentDialogInputs = {
  exchange: string;
  ticker: string;
  accountIndex: string;
  currencyCode: string;
  quantity: string;
  error: string;
};

type AddInvestmentDialogState = AddInvestmentDialogInputs & {
  open: boolean;
  accounts: InvestmentAccountQuery[];
  accountQueryByName?: Map<string, InvestmentAccountQuery>[];
  accountNames: string[];
  saving: boolean;
  isSnackbarOpen: boolean;
};

export default class AddInvestmentDialog extends React.Component<
  AddInvestmentDialogProps,
  AddInvestmentDialogState
> {
  constructor(props: AddInvestmentDialogProps) {
    super(props);

    this.state = {
      open: false,
      accounts: props.accounts,
      accountNames: [],
      exchange: "LON",
      error: "",
      ticker: "",
      accountIndex: "0",
      currencyCode: "",
      quantity: "0",
      saving: true,
      isSnackbarOpen: false,
    };
  }

  componentDidMount() {
    //refactor to ignore order!!!
    //future bug arisiing from this.
    //use maps instead.
    Promise.all(
      this.state.accounts.map((accountQueries) =>
        getQueryForAccounts(accountQueries)
      )
    )
      .then((accounts) => {
        let accountNames = accounts.map((x) => x.id);
        this.setState({ accountNames, saving: false });
      })
      .catch((error) => {
        this.setState({ error });
      });
  }

  handleSnackbarClose(event?: React.SyntheticEvent, reason?: string) {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ isSnackbarOpen: false, error: "" });
  }
  handleClose() {
    this.setState({ open: false });
  }
  handleClickOpen() {
    this.setState({ open: true });
  }

  render() {
    return (
      <div>
        <Button
          variant="outlined"
          color="primary"
          onClick={this.handleClickOpen.bind(this)}
        >
          Add new investment
        </Button>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose.bind(this)}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Add</DialogTitle>
          <DialogContent>
            {/* <DialogContentText>{this.state.error}</DialogContentText> */}
            <fieldset disabled={this.state.saving}>
              <select
                onChange={(e) => {
                  this.setState({ exchange: e.target.value });
                }}
                name="exchange"
                id="exchange"
                value={this.state.exchange}
              >
                <option value="LON">London Stock Exchange</option>
                <option value="NYSE">NYSE</option>
                <option value="NASDAQ">NASDAQ</option>
              </select>{" "}
              <br />
              <label>Ticker symbol</label>{" "}
              <input
                onChange={(e) => {
                  this.setState({ ticker: e.target.value });
                }}
                value={this.state.ticker}
                type="text"
              />
              <br />
              <label>3 digit currency code</label>{" "}
              <Currency
                onChange={(e: any) => {
                  this.setState({ currencyCode: e.target.value });
                }}
                value={this.state.currencyCode}
                type="text"
              />
              <br />
              <label>Investment Account</label>
              <select
                name="account"
                id="account"
                onChange={(e) => {
                  this.setState({ accountIndex: e.target.value });
                }}
                value={this.state.accountIndex}
              >
                {this.state.accountNames.map((i, index) => {
                  return (
                    <option key={index} value={index}>
                      {i}
                    </option>
                  );
                })}
              </select>{" "}
              <br />
              <label>Quantity</label>
              <TextField
                type="number"
                onChange={(e) => {
                  this.setState({ quantity: e.target.value });
                }}
                aria-describedby="my-helper-text"
                value={this.state.quantity}
              />
            </fieldset>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose.bind(this)} color="primary">
              Cancel
            </Button>
            <Button
              onClick={() => {
                if (this.state.ticker.replaceAll(" ", "") === "") {
                  this.setState({ error: "Ticker must not be empty" });
                  return;
                } else if (Number(this.state.quantity) <= 0) {
                  this.setState({
                    error: "Quantity must be greater than zero",
                  });
                  return;
                } else {
                  this.setState({ error: "" });
                }
                addInvestment({
                  exchange: this.state.exchange,
                  ticker: this.state.ticker,
                  currencyCode: this.state.currencyCode,
                  investmentAccountQuery: this.state.accounts[
                    Number(this.state.accountIndex)
                  ],
                  type: "stock",
                  quantity: Number(this.state.quantity),
                })
                  .then(() => {
                    this.setState({ error: "" });
                    this.handleClose();
                  })
                  .catch((e) => {
                    this.setState({
                      isSnackbarOpen: true,
                      error: e.toString(),
                    });
                  });
              }}
              color="primary"
            >
              Add
            </Button>
          </DialogActions>

          <Snackbar
            open={this.state.isSnackbarOpen}
            autoHideDuration={20000}
            onClose={this.handleSnackbarClose.bind(this)}
          >
            <Alert
              onClose={this.handleSnackbarClose.bind(this)}
              severity="error"
            >
              {this.state.error}
            </Alert>
          </Snackbar>
        </Dialog>
      </div>
    );
  }
}
