import React from "react";
import { Button, TextField } from "@material-ui/core";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { deleteBroker } from "./helper";
import { InvestmentProps } from "./Investments";

export type DeleteAccountsProps = {
  brokerId: string;
  successCallback: Function;
};

export default function DeleteAccounts(props: DeleteAccountsProps) {
  const [open, setOpen] = React.useState(false);
  const errorRef: React.RefObject<HTMLParagraphElement> = React.createRef();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    deleteBroker(props.brokerId)
      .then(() => {
        props.successCallback();
        setOpen(false);
      })
      .catch((e) => {
        console.log((errorRef.current.innerText = "ERROR!!!: " + e));
      });
  };

  return (
    <div>
      <Button variant="contained" color="secondary" onClick={handleClickOpen}>
        Delete
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        {/* <DialogTitle id="form-dialog-title"></DialogTitle> */}
        <DialogContent>
          <p ref={errorRef}></p>

          <DialogContentText>
            ARE YOU SURE YOU WANT TO DELETE?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            NO
          </Button>
          <Button onClick={handleDelete} color="primary">
            YES
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
